import axios from '@/axios';

const state = {
    isLoading: false,
    currencies: [],
    currency: localStorage.getItem("currency")
}

const getters = {
    currency: state => state.currency,
    currencySymbol: ({currencies, currency}) => {
        if (currency) {
            const curr = currencies.find(({id}) => currency === id);

            if (curr) {
                return curr.symbol;
            }
        }

        return '';
    },
}

const actions = {
    fetchCurrencies: async ({commit},) => {
        try {
            commit('setLoading', true);
            const {data: {status, payload}} = await axios.get(process.env.VUE_APP_API + 'currencies');
            if (status === 'OK') {
                const currencies = payload.map(({code: text, symbol}) => ({text, id: text, symbol}));
                commit('setCurrencies', currencies);

                if (localStorage.getItem("currency")) {
                    commit('setCurrency', localStorage.getItem("currency"));
                } else {
                    commit('setCurrency', currencies[0].text);
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            commit('setLoading', false);
        }
    },
    // changeCurrency: async ({commit, getters}, lcurrency) => {
    //     if (localeId) {
    //         commit('setCurrentLanguage', localeId);
    //
    //         try {
    //             const {data} = await axios.get('/localizations', {
    //                 params: {
    //                     lang_id: localeId
    //                 }
    //             });
    //
    //             if (data) {
    //                 let translations = {};
    //
    //                 if (Array.isArray(data) && data.length) {
    //                     data.forEach((translation) => translations[Object.keys(translation)[0]] = Object.values(translation)[0])
    //                 } else if (typeof data === 'object') {
    //                     translations = data;
    //                 }
    //
    //                 const {currentLanguageCode} = getters;
    //
    //                 i18n.setLocaleMessage(currentLanguageCode, translations)
    //                 i18n.locale = currentLanguageCode;
    //             }
    //         } catch (e) {
    //             console.log(e);
    //         }
    //     }
    // },
    // checkCurrency: async ({commit}) => {
    //
    // }
}

const mutations = {
    setLoading: (state, loading) => (state.isLoading = loading),
    setCurrencies: (state, currencies) => (state.currencies = currencies),
    setCurrency: (state, currency) => {
        console.log('Set currency');
        localStorage.setItem('currency', currency);
        state.currency = currency;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}