<template>
	<header class="header">
        <div class="wrapper">
          <div class="left">
            <router-link to="/" class="logo">
              <img src="./../assets/logo.svg" class="img"/>
              <img src="./../assets/logo-mini.svg" class="mob img"/>
            </router-link>
            <div class="select-container currency-select-container language-select-container">
              <Select2 v-if="currencies.length > 1" class="select2 single currency-select" v-model="curr" @select="selectCurrency($event)"  :options="currencies" :placeholder="curr" />
            </div>
            <ul class="nav">
              <template v-for="(item, i) in $parent.nav">
                <li class="nav__item" :key="i">
                  <router-link :to="item.link">{{item.title}}</router-link>
                </li>
              </template>
            </ul>
          </div>
          <div class="right">
            <router-link to="/cart" v-if="$parent.isAuth" :class="['icon', {'gray': !cartContents.length}]">
              <svg xmlns="http://www.w3.org/2000/svg" width="23.667" height="23" viewBox="0 0 23.667 23">
                <path id="grocery-store" d="M1,1A1,1,0,0,0,1,3H2.779a.694.694,0,0,1,.658.474l3.3,9.893a2.438,2.438,0,0,1,.053,1.361l-.271,1.088A2.585,2.585,0,0,0,9,19H21a1,1,0,0,0,0-2H9a.54.54,0,0,1-.545-.7l.2-.818A.641.641,0,0,1,9.281,15H20a1,1,0,0,0,.949-.684l2.666-8A1,1,0,0,0,22.666,5H6.555A.694.694,0,0,1,5.9,4.526L4.949,1.684A1,1,0,0,0,4,1ZM8,20a2,2,0,1,0,2,2A2,2,0,0,0,8,20Zm12,0a2,2,0,1,0,2,2A2,2,0,0,0,20,20Z" transform="translate(0 -1)" fill="#808a93"/>
              </svg>
              <div v-if="cartContents.length" class="indicator">
                {{cartContents.length}}
              </div>
            </router-link>
            <router-link to="/profile" class="button link-container dropdown-open" v-if="$parent.isAuth">
              <img src="./../assets/user.svg" class="img"/>
            </router-link>
            <div class="link-container" v-else>
              <div class="button modal-open" @click="openSignIn">
                <span>Sign In</span>
              </div>
            </div>
          </div>
        </div>
    </header>
</template>

<script>

import {mapMutations, mapState} from "vuex";
import Select2 from "vue3-select2-component";

export default {
	name: 'Header',
  props: ['cartContents'],
  components: {
    Select2
  },
	data: function() {
		return {
		}
	},
  computed: {
    ...mapState("app", ["currencies", "currency"]),
    curr: {
      set(currency) {
        this.setCurrency(currency)
      },
      get() {
        return this.currency;
      }
    }
  },
	methods: {
    selectCurrency(event) {
      this.setCurrency(event.id);
    },
    openSignIn() {
      this.$router.push({path: '/login'});
    },
    openSignUp() {
      this.$router.push({path: '/registration'});
    },
    openSaleSkinModal() {
      this.$emit('openSaleSkinModal')
    },
    logout() {
      this.$emit('logout')
    },
    ...mapMutations("app", ["setCurrentLanguage", 'setCurrency']),
  }
}
</script>
