<template>
  <div class="slider-container">
    <slick ref="slick"  :options="slickOptions" class="slider products" @swipe="handleSwipe">
      <div class="item" v-for="(item, index) in list" :key="index">
        <ProductCard :item="item" :tag="tag" :addToCartChosenItem="addToCartChosenItem" :sliderIsClickble="sliderIsClickble" @goToProduct="goToProduct" :currency="currency" @addToCart="addToCart"/>
      </div>
    </slick>
  </div>
</template>

<script>
import ProductCard from '../components/ProductCard.vue'
import Slick from 'vue-slick';

export default {
  name: 'SlickItem',
  props: ['list', 'tag', 'addToCartChosenItem', 'currency'],
  components: {
    Slick,
    ProductCard
  },
  data: function() {
    return {
      imgDomain: '',
      sliderIsClickble: true,
      slickOptions: {
        slidesToShow: 6,
        slidesToScroll: 5,
        arrows: true,
        infinite: false,
        dots: false,
        responsive: [
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 901,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 601,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      },
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    handleSwipe() {
      let self = this;
      self.sliderIsClickble = false;
      setTimeout(()=> {
        self.sliderIsClickble = true;
      }, 300)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    }
  }
}
</script>