<template>
	<div class="menu">
		<ul class="nav">
			<template v-for="(item, i) in $parent.nav">
			<li class="nav__item" :key="i" v-if="item.link != '/privacy' && item.link != '/terms'">
				<router-link :to="item.link">
				<span v-if="item.title == 'New Arrivals'">New</span> 
				<template v-if="item.title == 'New Arrivals'">
					Arrivals
				</template>
				<template v-else>
					{{item.title != 'Sale'? item.title : ''}}
				</template>
				<span class="red" v-if="item.title == 'Sale'">Sale</span>
				</router-link>
			</li>
			</template>
			<!-- <div class="nav__item" v-if="$route.name == 'Profile'  || $route.name == 'SellOffers'">
			<a @click="$emit('openSaleSkinModal')">Sale your skin</a>
			</div> -->

      <div class="select-container currency-select-container language-select-container">
        <Select2 v-if="currencies.length > 1" class="select2 single currency-select" v-model="curr" @select="selectCurrency($event)"  :options="currencies" :placeholder="curr" />
      </div>
		</ul>
	</div>
</template>

<script>
/*import Vue from 'vue';
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)*/
import {mapMutations, mapState} from "vuex";
import Select2 from "vue3-select2-component";

export default {
	name: 'Menu',
	props: [],
	data: function() {
		return {
			
		}
	},
  components: {
    Select2
  },
  computed: {
    ...mapState("app", ["currencies", "currency"]),
    curr: {
      set(currency) {
        this.setCurrency(currency)
      },
      get() {
        return this.currency;
      }
    }
  },
	methods: {
    selectCurrency(event) {
      this.setCurrency(event.id);
    },
    ...mapMutations("app", ['setCurrency']),
	},
}
</script>
