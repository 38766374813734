<template>
	<div class="item-wrapper">
		<div class="item-top">
			<div class="price-container">
				<a @click="goToSliderItem(item)" class="title">{{item.title}}</a>
			</div>
		</div>
		<div class="item-bottom">
			<div class="preview">
				<img @click="goToSliderItem(item)" :src="imgDomain + item.img_url" class="img"/>
				<!-- <div v-if="tag" class="tag">
					<span>{{tag}}</span>
				</div> -->
				<transition name="fade">
					<span class="title" v-if="addToCartChosenItem == item.id">Added</span>
				</transition>
			</div>
			<div class="desc">{{item.type}}</div>
			<div class="price title">
				<span>{{item.price}} <span class="currency">{{ currencySymbol }}</span></span>
				<span class="discount" v-if="item.old_price != '0.00'">{{item.old_price}} <span class="currency">{{ currencySymbol }}</span></span>
			</div>
			<img class="img" src="./../assets/grocery-store.svg" @click="$emit('addToCart',item, item.id)" />
		</div>
	</div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
	name: 'ProductCard',
	props: ['item', 'addToCartChosenItem', 'sliderIsClickble', 'tag', 'currency'],
	data: function() {
		return {
			imgDomain: ''
		}
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
	},
	methods: {
		goToSliderItem(item) {
			let self = this;
			setTimeout(()=> {
				if (self.sliderIsClickble == true) {
					self.$emit('goToProduct', item);
				}
			}, 200)
		},
	},
  computed: {
    ...mapGetters('app', ['currencySymbol'])
  }
}
</script>
